import React from "react"
import {ApiErrorThrow, defaultSnackbarOptions} from "../../Config";
import {withSnackbar} from "react-simple-snackbar";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Collapse,
    NavLink,
    Row, Spinner, Table,
} from "reactstrap";
import classnames from "classnames";
import CreditCardTab from "./Payment/CreditCardTab";
import BkmExpressTab from "./Payment/BkmExpressTab";
import CustomCheckbox from "../../Elements/CustomCheckbox";
import PaymentModel from "../../Models/PaymentModel";
import {matchPath} from "react-router";
import {GetTitle} from "../../Routes/AdminRoute";
import ThreeDModal from "./Payment/ThreeDModal";
import PaymentFailedModal from "./Payment/PaymentFailedModal";
import PaymentSuccessModal from "./Payment/PaymentSuccessModal";
import OrderModel from "../../Models/OrderModel";
import ShowPrice from "../../Elements/ShowPrice";
import ButtonLoad from "../../Elements/ButtonLoad";
import {get_main_config, react_intl} from "../../index";
import GarantiPayTab from "./Payment/GarantiPayTab";
import {FormattedMessage} from "react-intl";
import TermsModal from "./TermsModal";

const getParams = (pathname, auth) => {
    let p = !auth ? `/pay/:orderId/:token` : `/cp/orders/:orderId/pay`
    const matchProfile = matchPath(pathname, {
        path: p
    });
    return (matchProfile && matchProfile.params) || {};
};

export const get_payment_method_id = (methodSlug) => {
    let methods = get_main_config("payment_methods")
    return Object.keys(methods).find(key => {
        return methods[key] === methodSlug
    }) || -1
}

class PayPageBase extends React.Component {
    orderId = 0
    auth = false
    token = ""
    state = {
        cc_details: {
            number: '',
            holder: '',
            expiry_month: '',
            expiry_year: '',
            cvv: '',
        },
        installment: 1,
        accepted: false,
        activeTab: 0,
        popupShow: false,
        errorShow: false,
        successShow: false,
        iframeDoc: "",
        errorMessage: "",
        orderDetails: {
            currency: {}
        },
        orderDetailsLoading: true,
        numberValid: false,
        cvvValid: false,
        expValid: true,
        holderValid: true
    }

    toggle = tab => {
        this.setState({
            activeTab: tab
        })
    }

    loadOrder = () => {
        let {openSnackbar, auth} = this.props
        let loader
        if (!auth) {
            let __token = this.token;
            window.dispatchEvent(new CustomEvent("orderTokenChanged", {
                detail: {token: __token}
            }));
            loader = OrderModel.getByIdWithToken(this.orderId, __token, ["currency"])
        } else {
            loader = OrderModel.getById(this.orderId, ["currency"])
        }

        loader.then(r => r.json())
            .then(r => {
                let data = r.data.attributes
                if (typeof r.included.currencies === "object")
                    data.currency = r.included.currencies[0].data

                this.setState({
                    orderDetails: data,
                    orderDetailsLoading: false
                })
            }).catch(r => {
            this.setState({orderDetailsLoading: false})
            ApiErrorThrow(r, openSnackbar)
        })
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "client.pay_page_base.pay_title"}))
        this.loadOrder()
    }

    paymentCompleted = (data) => {
        let self = this
        let redirect_url = `/cp/orders/${self.orderId}`
        if (self.token)
            redirect_url = `/show-invoice/${self.orderId}/${self.token}`
        self.props.history.push(redirect_url)
    }

    paymentFailed = (data) => {
        let self = this
        self.setState({
            popupShow: false,
            errorShow: true,
            errorMessage: data.message || ""
        })
    }

    constructor(props) {
        super(props);
        this.ccOnChange = this.ccOnChange.bind(this)
        this.popupToggle = this.popupToggle.bind(this)
        let params = getParams(props.location.pathname, props.auth)
        this.orderId = params.orderId
        this.token = params.token

        let self = this

        window.onmessage = function (e) {
            let {data} = e
            switch (data.event) {
                case 'payment_completed':
                    self.paymentCompleted(data)
                    break;
                case 'payment_failed':
                    self.paymentFailed(data)
                    break;
            }
        }
    }

    ccOnChange = (name, value) => {
        let cc_details = {...this.state.cc_details}
        if (name === "holder") {
            if (value.match(/[^a-zA-Z' 'wığüşöçĞÜŞÖÇİ]/g)) {
                value = value.replace(/[^a-zA-ZwığüşöçĞÜŞÖÇİ]/g, '');
            }
        }
        cc_details[name] = value
        this.setState({cc_details})
    }

    installment_changed = (installment) => {
        this.setState({
            installment
        })
    }

    paymentMethods = (getItem) => {
        let paymentMethods = [
            {
                name: <><i className={"icon-line-awesome-credit-card"}/> <FormattedMessage
                    id={"client.pay_page_base.credit_or_bank_card"}/></>,
                logo: "",
                slug: "CC",
                content: <CreditCardTab
                    auth={this.props.auth}
                    token={this.token}
                    installment_changed={this.installment_changed}
                    installment={this.state.installment}
                    orderId={this.orderId}
                    onChange={this.ccOnChange}
                    orderDetails={this.state.orderDetails}

                    isValidNumber={this.state.numberValid}
                    isValidExp={this.state.expValid}
                    isValidCVV={this.state.cvvValid}
                    isValidHolder={this.state.holderValid}

                    numberValidation={(result) => {
                        if (result !== this.state.numberValid) this.setState({numberValid: result})
                    }}
                    expiryValidation={(result) => {
                        if (result !== this.state.expValid) this.setState({expValid: result})
                    }}
                    cvvValidation={(result) => {
                        if (result !== this.state.cvvValid) this.setState({cvvValid: result})
                    }}
                    holderValidation={(result) => {
                        if (result !== this.state.holderValid) this.setState({holderValid: result})
                    }}

                    details={this.state.cc_details}/>,
                buttontext: react_intl.formatMessage({id: "client.pay_page_base.pay_btn_txt"})
            }
        ]

        let bkmId = get_payment_method_id("BkmExpress")
        if (bkmId !== -1) {
            paymentMethods.push({
                name: "BKM Express",
                logo: "",
                slug: "BkmExpress",
                method_id: bkmId,
                content: <BkmExpressTab/>,
                buttontext: react_intl.formatMessage({id: "client.pay_page_base.pay_bkm_btn_txt"})
            })
        }

        let garantiPayId = get_payment_method_id("GarantiPay")
        if (garantiPayId !== -1) {
            paymentMethods.push({
                name: "Garanti Pay",
                logo: "",
                slug: "GarantiPay",
                method_id: garantiPayId,
                content: <GarantiPayTab/>,
                buttontext: react_intl.formatMessage({id: "client.pay_page_base.pay_garantipay_btn_txt"})
            })
        }

        if (typeof getItem === "undefined")
            return paymentMethods
        return paymentMethods[getItem]
    }

    popupToggle = () => {
        this.setState({popupShow: !this.state.popupShow})
    }

    errorToggle = () => {
        this.setState({errorShow: !this.state.errorShow})
    }
    successToggle = () => {
        this.setState({successShow: !this.state.successShow})
    }

    render() {
        let {activeTab, orderDetails} = this.state
        let _pMethods = this.paymentMethods()
        let _aMethod = this.paymentMethods(this.state.activeTab)

        if (typeof orderDetails.currency !== "object")
            orderDetails.currency = {}

        return <>
            <ThreeDModal
                onClosed={() => {
                    this.setState({iframeDoc: ""})
                }}
                methodSelected={_aMethod.slug}
                popupShow={this.state.popupShow}
                iframeDoc={this.state.iframeDoc}
                popupToggle={this.popupToggle}
                paymentDuring={this.state.popupShow}
            />

            <PaymentFailedModal
                message={this.state.errorMessage}
                isOpen={this.state.errorShow}
                toggle={this.errorToggle}
            />

            <PaymentSuccessModal
                isOpen={this.state.successShow}
                toggle={this.successToggle}
            />

            <div className={"margin-bottom-25 text-center p-3 bg-light text-dark"}>
                <h2><FormattedMessage id={"client.pay_page_base.pay_h2"}/></h2>
            </div>

            <div className={"w-100 pl-3 pr-3"}>
                <Row>
                    <Col md={9}>
                        <div>
                            {_pMethods.map((item, num) => {
                                return <>
                                    <Card className={"mb-2"}>
                                        <CardHeader className={classnames("cursor-pointer payment-method-header", {
                                            "bg-dark text-white": activeTab === num,
                                            "bg-light text-dark": activeTab !== num,
                                        })}>
                                            <NavLink
                                                className={classnames({active: activeTab === num})}
                                                onClick={() => {
                                                    this.toggle(num);
                                                }}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </CardHeader>
                                        <CardBody className={"p-0"}>
                                            <Collapse tabId={num} isOpen={activeTab === num}>
                                                {item.content}
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                </>
                            })}
                        </div>
                    </Col>
                    <Col md={3}>
                        {this.state.orderDetailsLoading ? <div className={"text-center"}>
                            <Spinner size={"sm"}/>
                        </div> : <Card className={""}>
                            <CardHeader className={"bg-dark text-white"} style={{padding: "20px", fontSize: "18px"}}>
                                <FormattedMessage id={"client.pay_page_base.invoice_details"}/>
                            </CardHeader>
                            <CardBody className={"pl-0 pr-0 pt-0"}>
                                <Table striped={1}>
                                    <tbody>
                                    <tr>
                                        <th><FormattedMessage id={"client.pay_page_base.invoice_no"}/></th>
                                        <td>{orderDetails.order_num}</td>
                                    </tr>
                                    <tr>
                                        <th><FormattedMessage id={"client.pay_page_base.amount"}/></th>
                                        <td><ShowPrice amount={orderDetails.balance}
                                                       currencyprefix={orderDetails.currency.prefix}
                                                       currencysuffix={orderDetails.currency.suffix}/></td>
                                    </tr>
                                    </tbody>
                                </Table>

                                <div className={classnames("text-center", {"d-none": !this.props.auth})}>
                                    <ButtonLoad
                                        onClick={() => {
                                            this.props.history.push(`/cp/orders/${orderDetails.id}`)
                                        }}
                                        text={react_intl.formatMessage({id: "client.pay_page_base.go_to_invoice"})}
                                        color={"primary"}
                                    />
                                </div>

                            </CardBody>
                        </Card>}
                        <div className={"text-right padding-top-25"}>
                            <ul className={"list-unstyled"}>
                                <li>
                                    <CustomCheckbox
                                        checked={this.state.accepted}
                                        onChange={(e) => {
                                            this.setState({
                                                accepted: e.target.checked
                                            })
                                        }}
                                    />
                                    <TermsModal onAccept={() => {
                                        this.setState({
                                            accepted: true
                                        })
                                    }} onCancel={() => {
                                        this.setState({
                                            accepted: false
                                        })
                                    }}/>
                                </li>
                            </ul>
                        </div>
                        <p className={"text-right mt-3"}>
                            <Button
                                size={"lg"}
                                disabled={!this.state.accepted || this.state.orderDetailsLoading}
                                onClick={() => {
                                    this.startPayment()
                                }}
                                color={"dark"}>{_aMethod.buttontext}</Button>
                        </p>
                    </Col>
                </Row>
            </div>

        </>
    }

    startPayment() {
        let {openSnackbar, auth} = this.props
        let {cc_details, installment, numberValid, expValid, cvvValid, holderValid} = this.state

        let active_method = this.paymentMethods(this.state.activeTab)
        if (active_method.slug === "CC") {

            if (!numberValid) {
                openSnackbar(react_intl.formatMessage({id: "client.payment_validation.check_cc_number"}))
                return false
            }

            if (cc_details.expiry_month === "" || cc_details.expiry_year === "") {
                this.setState({expValid: false})
                openSnackbar(react_intl.formatMessage({id: "client.payment_validation.check_cc_expiry"}))
                return false;
            }

            if (cc_details.holder === "") {
                this.setState({holderValid: false})
                openSnackbar(react_intl.formatMessage({id: "client.payment_validation.check_cc_holder"}))
                return false;
            }

            if (!cvvValid) {
                openSnackbar(react_intl.formatMessage({id: "client.payment_validation.check_cvv_number"}))
                return false;
            }
        }

        if (!this.state.accepted) {
            openSnackbar(<FormattedMessage id={"client.pay_page_base.accept_terms_msg"}/>)
            return false
        }
        this.setState({
            popupShow: true
        })

        let _aMethod = this.paymentMethods(this.state.activeTab)
        let payment_object = {
            cc_number: cc_details.number,
            cc_cvv: cc_details.cvv,
            cc_exp: `${cc_details.expiry_month}/${cc_details.expiry_year}`,
            cc_holder: cc_details.holder,
            order_id: this.orderId,
            installment: installment
        }
        if (typeof _aMethod.method_id !== "undefined")
            payment_object.use_method = _aMethod.method_id

        let make;

        if (auth)
            make = PaymentModel.make(payment_object)
        else
            make = PaymentModel.makeUnauth(payment_object, this.token)

        make.then(r => r.json())
            .then(resp => {
                let status = resp.status
                if (status) {
                    let {form} = resp.data.attributes
                    this.setState({
                        iframeDoc: form
                    })
                } else {
                    let message = ""
                    openSnackbar(message)
                }
            }).catch(r => {
            this.popupToggle()
            ApiErrorThrow(r, openSnackbar)
        })
        return "";
    }
}

export default withSnackbar(PayPageBase, defaultSnackbarOptions)